/**
 * Created by jiachenpan on 16/11/18.
 */

export function isvalidUsername(str) {
	const valid_map = ['admin', 'editor']
	return valid_map.indexOf(str.trim()) >= 0
}

/** 合法uri */
export function validateURL(textval) {
	const urlregex = /^(https?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/
	return urlregex.test(textval)
}

/** 小写字母 */
export function validateLowerCase(str) {
	const reg = /^[a-z]+$/
	return reg.test(str)
}

/** 小写字母和数字 */
export function validateLowerCaseAndNumber(str) {
	const reg = /^[a-z0-9]+$/
	return reg.test(str)
}

/** 大小写字母和数字 */
export function validateAlphabetsAndNumber(str) {
	const reg = /^[A-Za-z0-9]+$/
	return reg.test(str)
}

/** 大写字母 */
export function validateUpperCase(str) {
	const reg = /^[A-Z]+$/
	return reg.test(str)
}

/** 大小写字母 */
export function validatAlphabets(str) {
	const reg = /^[A-Za-z]+$/
	return reg.test(str)
}

/**
 * validate email
 * @param email
 * @returns {boolean}
 */
export function validateEmail(email) {
	const re = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/
	return re.test(email)
}

/**
 * validate phone
 * @param phone
 * @returns {*|boolean}
 */
export function validatePhone(phone) {
	const reg = /^0?(13[0-9]|14[0-9]|15[0-9]|16[0-9]|17[0-9]|18[0-9]|19[0-9])[0-9]{8}$/
	return reg.test(phone)
}

/**
 * validate price
 * @param price
 * @returns {*|boolean}
 */
export function validatePrice(price) {
	const reg = /(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/
	return reg.test(price)
}


/**
 * validate uname
 */
export function validateUname(uname) {
  const  reg = /(^[0-9]{6,20}$)|(^[a-zA-Z]{6,20}$)|(^[a-zA-Z]([0-9a-zA-Z]{5,20}$))|(^[0-9]([0-9a-zA-Z]{5,20}$)|_)/
  return reg.test(uname)
}
