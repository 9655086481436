/**
 * Created by LFJ on 2020/08/28.
 * API
 */

import request from '@/utils/request'

/**
 * 平台搜索待开发票订单表
 */
export function getInvoiceOrderList(params) {
  return request({
    url: 'seller/shops/shopReceipt/getWaitReceiptOrderList',
    method: 'get',
    params
  })
}

/**
 * 平台搜索待开发票订单表总金额(包括运费)
 */
export function getWaitOrderTotalPrice(params) {
  return request({
    url: 'seller/shops/shopReceipt/getWaitOrderTotalPrice',
    method: 'get',
    params
  })
}

/**
 * 平台搜索店铺发票信息详情
 */
export function getDetail() {
  return request({
    url: 'seller/shops/shopReceipt/getDetail',
    method: 'get'
  })
}

/**
 * 平台添加索取发票记录表
 */
export function addHistory(data) {
  return request({
    url: 'seller/shops/shopReceipt/addHistory',
    method: 'put',
    data
  })
}

/**
 * 平台添加/修改店铺发票信息
 */
export function shopReceipt(data) {
  return request({
    url: 'seller/shops/shopReceipt',
    method: 'post',
    data
  })
}

//////////////////////////////////////////////////////////////////店铺发票信息记录
/**
 * 平台审核发票
 */
export function aduditHistory(data) {
  return request({
    url: 'seller/shops/shopReceiptHistory/aduditHitory',
    method: 'put',
    data
  })
}

/**
 * 平台搜索审核发票记录列表
 */
export function getAuditList(params) {
  return request({
    url: 'seller/shops/shopReceiptHistory/getAuditList',
    method: 'get',
    params
  })
}

/**
 * 平台搜索发票记录列表
 */
export function getHistoryList(params) {
  return request({
    url: 'seller/shops/shopReceiptHistory/getHistoryList',
    method: 'get',
    params
  })
}

/**
 * 搜索发票记录详情
 */
export function getInfo(id) {
  return request({
    url: `seller/shops/shopReceiptHistory/getInfo/${id}`,
    method: 'get'
  })
}

/**
 * 平台搜索记录表下的订单信息列表
 */
export function getOrderList(params) {
  return request({
    url: 'seller/shops/shopReceiptHistory/getOrderList',
    method: 'get',
    params
  })
}

/**
 * 平台搜索记录表下的订单信息列表
 */
export function getOrderListNew(params) {
  return request({
    url: 'seller/shops/shopReceiptHistory/getOrderListNew',
    method: 'get',
    params
  })
}

/**
 * 平台邮寄发票
 */
export function postReceiptByHistoryId(history_id,data) {
  return request({
    url: `seller/shops/shopReceiptHistory/postReceipt/${history_id}`,
    method: 'put',
    data
  })
}

/**
 * 获取快递公司列表
 */
export function getExpressCompany(params) {
  return request({
    url: 'seller/systems/logi-companies',
    method: 'get',
    params
  })
}

/**
 * 获取物流
 */
export function getExpress(params) {
  return request({
    url: `seller/shops/shopReceiptHistory/getExpressList`,
    method: 'get',
    params
  })
}
/**
 * 获取物流By Id
 */
export function getExpressByHistoryId(params) {
  return request({
    url: `seller/shops/shopReceiptHistory/getExpressListByHistoryId`,
    method: 'get',
    params
  })
}
//////////////////////////////////////////////////////////////平台发票收票地址相关
/**
 * 搜索平台发票收票地址列表
 */
export function getShopReceiptAddressList() {
  return request({
    url: 'seller/shops/shopReceiptAddress',
    method: 'get'
  })
}

/**
 * 添加平台发票收票地址
 */
export function addShopReceiptAddress(data) {
  return request({
    url: 'seller/shops/shopReceiptAddress',
    method: 'post',
    data
  })
}

/**
 * 搜索平台发票收票地址详情
 */
export function getShopReceiptAddressDetailById(id) {
  return request({
    url: `seller/shops/shopReceiptAddress/${id}`,
    method: 'get'
  })
}

/**
 * 修改平台发票收票地址
 */
export function editShopReceiptAddressDetailById(id,data) {
  return request({
    url: `seller/shops/shopReceiptAddress/${id}`,
    method: 'put',
    data
  })
}

/**
 * 删除平台发票收票地址
 */
export function delShopReceiptAddressDetailById(id) {
  return request({
    url: `seller/shops/shopReceiptAddress/${id}`,
    method: 'delete'
  })
}

/**
 * 设置默认地址
 */
export function setDefaultShopReceiptAddress(id) {
  return request({
    url: `seller/shops/shopReceiptAddress/default/${id}`,
    method: 'put'
  })
}

/**
 * 获取默认地址
 */
export function getDefaultShopReceiptAddress() {
  return request({
    url: `seller/shops/shopReceiptAddress/getDefault`,
    method: 'get'
  })
}


export function getJDExpress(params) {
  return request({
    url: 'seller/shops/shopReceipt/getJDWayBill',
    method: 'get',
    params
  })
}

// 查看电子发票
export function showElcInfo(sn) {
  return request({
    url: `seller/shops/shopReceiptHistory/getElcInfo/${sn}`,
    method: 'get',
    loading: false
  })
}
