<template>
  <div class="msgMsg">
    <h1>设置发票信息</h1>
    <div class="tables">
      <span class="title">寄送地址：</span>
      <el-button type="primary" size="medium" @click="addDielog" style="float: right;margin-bottom: 5px">添加</el-button>
      <el-table :data="tableData" border style="width:100%">
        <el-table-column prop="receive_name" label="收件人姓名"></el-table-column>
        <el-table-column prop="receive_phone" label="联系电话"></el-table-column>
        <el-table-column prop="receive_addr" label="详细地址"></el-table-column>
        <el-table-column label="是否为默认">
          <template slot-scope="scope">{{ scope.row.is_default ? '是' : '否' }}</template>
        </el-table-column>
        <el-table-column label="操作" width="400">
          <template slot-scope="scope">
            <el-button size="mini" type="primary" @click="defaultShopReceiptAddress(scope.row)">设为默认</el-button>
            <el-button size="mini" type="primary" @click="detailShopReceiptAddress(scope.row)">编辑</el-button>
            <el-button size="mini" type="primary" @click="delShopReceiptAddress(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>

      <div class="form_item">
        <div style="width:800px;max-width:100%;">
          <div style="margin-bottom:20px;text-align:left;padding-left:60px;">请您完善以下发票信息。（发票信息仅限一条，请您慎重填写。）</div>
          <el-form :model="InvoiceData" :rules="InvoiceRules" label-width="145px">
            <el-form-item label-width="60px" style="text-align:left;">
              <el-radio-group v-model="InvoiceData.receipt_type" @change="handleradioChange()">
                <el-radio border label="ELECTRO">电子普通发票</el-radio>
                <!--                <el-radio border label="VATORDINARY">增值税普通发票</el-radio>-->
                <el-radio border label="VATOSPECIAL">增值税专用发票</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="发票抬头" prop="receipt_title">
              <el-input :placeholder="radio1 == 'one' ? '企业/个人' : '企业名称'"
                v-model="InvoiceData.receipt_title"></el-input>
            </el-form-item>
            <el-form-item label="企业税号" prop="tax_no">
              <el-input placeholder="请输入企业税号" v-model="InvoiceData.tax_no"></el-input>
            </el-form-item>
            <span class="info2"
              v-if="InvoiceData.receipt_type == 'VATOSPECIAL'">如果您的营业执照是三证合一或五证合一的证照，请填写最新的营业执照编号。</span>
            <el-form-item label="开户银行" prop="bank_name" v-if="InvoiceData.receipt_type == 'VATOSPECIAL'">
              <el-input placeholder="请输入您开户许可证上的开户银行" v-model="InvoiceData.bank_name"></el-input>
            </el-form-item>
            <el-form-item label="开户账号" prop="bank_account" v-if="InvoiceData.receipt_type == 'VATOSPECIAL'">
              <el-input placeholder="请输入您开户许可证上的银行账号" v-model="InvoiceData.bank_account"></el-input>
            </el-form-item>

            <el-form-item label="注册地址" prop="reg_addr" v-if="InvoiceData.receipt_type == 'VATOSPECIAL'">
              <el-input placeholder="请输入您营业执照上的注册地址" v-model="InvoiceData.reg_addr"></el-input>
            </el-form-item>
            <el-form-item label="注册电话" prop="reg_tel" v-if="InvoiceData.receipt_type == 'VATOSPECIAL'">
              <el-input placeholder="请输入您公司有效电话" v-model="InvoiceData.reg_tel"></el-input>
            </el-form-item>
          </el-form>
        </div>
        <el-button @click="postInvoiceTitleDetail" type="primary" size="medium">提交</el-button>
        <el-button @click="$router.go(-1)" type="primary" size="medium">返回</el-button>
      </div>
    </div>

    <!-- 添加/编辑 -->
    <el-dialog :close-on-click-modal="false" :title="isAdd" :visible.sync="dielog" width="600px" @close="cancel">
      <div class="addMealForm">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="135px">
          <el-form-item label="收件人" prop="receive_name">
            <el-input style="width: 300px;" placeholder="请输入收件人" v-model="ruleForm.receive_name"></el-input>
          </el-form-item>
          <el-form-item label="手机号" prop="receive_phone">
            <el-input style="width: 300px;" placeholder="请输入手机号" v-model="ruleForm.receive_phone"></el-input>
          </el-form-item>
          <el-form-item label="收货地址">
            <!-- <en-address-selector :pramas='ruleForm' :provinceList='provinceList' /> -->
            <el-select v-model="ruleForm.province_id" @change="getAddressList('provice')" placeholder="省">
              <el-option v-for="item in provinceList" :key="item.id" :label="item.local_name"
                :value="item.id"></el-option>
            </el-select>
            <el-select v-model="ruleForm.city_id" @change="getAddressList('city')" placeholder="市">
              <el-option v-for="item in cityList" :key="item.id" :label="item.local_name" :value="item.id"></el-option>
            </el-select>
            <el-select v-model="ruleForm.county_id" @change="getAddressList('county')" placeholder="区">
              <el-option v-for="item in countyList" :key="item.id" :label="item.local_name"
                :value="item.id"></el-option>
            </el-select>
            <el-select v-model="ruleForm.town_id" placeholder="乡镇">
              <el-option v-for="item in townList" :key="item.id" :label="item.local_name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="详细地址" prop="receive_addr">
            <el-input style="width: 300px;" placeholder="请输入详细地址" v-model="ruleForm.receive_addr"></el-input>
          </el-form-item>
          <el-form-item label="设为默认">
            <el-switch v-model="ruleForm.is_default"></el-switch>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="default" @click="dielog = false" size="small">取消</el-button>
        <el-button type="primary" @click="accountSubmit()" size="small">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import * as API_Invoice from "@/api/invoice";
import { validatePhone } from "@/utils/validate";
import * as API_Address from '@/api/express'
export default {
  name: "Invoicemangerdetail",
  created () { },
  data () {
    const phoneValidate = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入手机号"));
      }
      if (!validatePhone(value)) {
        callback(new Error("请输入正确的手机号"));
      }
      callback();
    };
    return {
      radio1: '',
      tableData: [],
      ruleForm: {
        id: "",
        receive_name: "",
        receive_phone: "",
        receive_addr: "",
        is_default: false,
        province_id: '',
        city_id: '',
        county_id: '',
        town_id: '',
        province: '',
        city: '',
        county: '',
        town: ''
      },
      rules: {
        receive_name: [
          {
            required: true,
            message: "请输入收件人",
            trigger: ["blur", "change"],
          },
          {
            min: 1,
            max: 10,
            message: "长度在 1 到 10 个字符",
            trigger: ["blur", "change"],
          },
        ],
        receive_phone: [
          {
            required: true,
            validator: phoneValidate,
            trigger: ["blur", "change"],
          },
        ],
        receive_addr: [
          {
            required: true,
            message: "地址不能为空",
            trigger: ["blur", "change"],
          },
        ],
      },
      isAdd: "添加",
      dielog: false,
      InvoiceData: {
        receipt_id: "",
        receipt_type: "VATOSPECIAL",
        receipt_title: "",
        tax_no: "",
        bank_name: "",
        bank_account: "",
        reg_addr: "",
        reg_tel: "",
      },
      InvoiceRules: {
        receipt_title: [
          { required: true, message: '请输入发票抬头', trigger: 'blur' }
        ],
        tax_no: [
          { required: true, message: '请输入企业税号', trigger: 'blur' }
        ]
      },
      provinceList: [],
      cityList: [],
      countyList: [],
      townList: []
    };
  },
  mounted () {
    this.getShopReceiptAddress();
    this.getInvoiceTitleDetail();
  },
  methods: {
    clearRuleFoem () {
      // 清空表单数据
      this.ruleForm.receive_name = "";
      this.ruleForm.receive_phone = "";
      this.ruleForm.receive_addr = "";
      this.ruleForm.is_default = false;
      this.ruleForm.province_id = ''
      this.ruleForm.city_id = ''
      this.ruleForm.county_id = ''
      this.ruleForm.town_id = ''
    },
    addDielog () {
      this.isAdd == "添加";
      this.dielog = true;
      API_Address.getBaseAddress(0).then(res => {
        this.provinceList = res
      })
    },
    // 获取地址列表
    getAddressList (type) {
      if (type === 'provice') {
        this.ruleForm.city_id = ''
        this.ruleForm.county_id = ''
        this.ruleForm.town = ''
        this.cityList = []
        this.countyList = []
        this.townList = []
        API_Address.getBaseAddress(this.ruleForm.province_id).then(res => {
          this.cityList = res
        })
      } else if (type === 'city') {
        this.ruleForm.county_id = ''
        this.ruleForm.town = ''
        this.countyList = []
        this.townList = []
        API_Address.getBaseAddress(this.ruleForm.city_id).then(res => {
          this.countyList = res
        })
      } else {
        this.ruleForm.town = ''
        this.townList = []
        API_Address.getBaseAddress(this.ruleForm.county_id).then(res => {
          this.townList = res
        })
      }
    },
    accountSubmit () {
      // 添加/编辑
      this.provinceList.forEach(item => {
        if (item.id === this.ruleForm.province_id) {
          this.ruleForm.province = item.local_name
        }
      })
      this.cityList.forEach(item => {
        if (item.id === this.ruleForm.city_id) {
          this.ruleForm.city = item.local_name
        }
      })
      this.countyList.forEach(item => {
        if (item.id === this.ruleForm.county_id) {
          this.ruleForm.county = item.local_name
        }
      })
      this.townList.forEach(item => {
        if (item.id === this.ruleForm.town_id) {
          this.ruleForm.town = item.local_name
        }
      })
      const dataers = JSON.parse(JSON.stringify(this.ruleForm));
      dataers.is_default ? (dataers.is_default = 1) : (dataers.is_default = 0);
      if (this.isAdd === "添加") {
        // 添加
        API_Invoice.addShopReceiptAddress(dataers).then((res) => {
          this.clearRuleFoem();
          this.dielog = false;
          this.getShopReceiptAddress();
        });
      } else {
        // 编辑
        API_Invoice.editShopReceiptAddressDetailById(dataers.id, dataers).then(
          (res) => {
            this.clearRuleFoem();
            this.dielog = false;
            this.getShopReceiptAddress();
          }
        );
      }
    },
    getShopReceiptAddress () {
      // 请求列表
      API_Invoice.getShopReceiptAddressList().then((res) => {
        this.tableData = res;
      });
    },
    delShopReceiptAddress (row) {
      // 删除
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          API_Invoice.delShopReceiptAddressDetailById(row.id).then((res) => {
            this.getShopReceiptAddress();
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    detailShopReceiptAddress (row) {
      // 获取详情
      API_Invoice.getShopReceiptAddressDetailById(row.id).then((res) => {
        this.isAdd = "编辑";
        this.ruleForm.id = res.id;
        this.ruleForm.receive_name = res.receive_name;
        this.ruleForm.receive_addr = res.receive_addr;
        this.ruleForm.receive_phone = res.receive_phone;
        this.ruleForm.province_id = res.province
        this.ruleForm.city_id = res.city
        this.ruleForm.county_id = res.county
        this.ruleForm.town_id = res.town
        res.is_default === 1
          ? (this.ruleForm.is_default = true)
          : (this.ruleForm.is_default = false);
        this.dielog = true;
        API_Address.getBaseAddress(0).then(res => {
          this.provinceList = res
        })
      })
    },
    defaultShopReceiptAddress (row) {
      // 设置默认地址
      API_Invoice.setDefaultShopReceiptAddress(row.id).then((res) => {
        this.getShopReceiptAddress();
        this.$message({
          type: "success",
          message: `设置默认成功`,
        });
      });
    },
    getInvoiceTitleDetail () {
      // 搜索发票抬头信息
      API_Invoice.getDetail().then((res) => {
        if (res) {
          // this.InvoiceData = JSON.parse(JSON.stringify(res));
          this.InvoiceData = res.shop_receipt
        }
      });
    },
    postInvoiceTitleDetail () {
      // 添加或编辑发票抬头信息
      API_Invoice.shopReceipt(this.InvoiceData).then((res) => {
        this.$message({
          type: "success",
          message: "操作成功",
        });
        this.$router.go(-1)
      });
    },

    handleradioChange () {
      console.log(this.radio1);
    },
    GET_ManageList () { },
    handleSizeChange (val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange (val) {
      console.log(`当前页: ${val}`);
    },

    cancel () {
      this.dielog = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.form_item {
  margin-top: 20px;
  width: 100%;

  ::v-deep .el-form-item--mini .el-form-item__content {
    line-height: 0;
  }

  .info2 {
    // margin-left: 145px;
    margin-top: -20px;
    display: inline-block;
    text-align: left;
    font-size: 14px;
    color: #cccccc;
  }
}

.msgMsg {
  position: relative;
  background: #fff;
  padding: 10px 20px;
  border-radius: 5px;
}

.gobuy {
  margin: 20px 0px;
}

.tables {
  text-align: center;
}

.el-table--border .cell {
  text-align: center;
}

.tables .el-table__header-wrapper {
  background-color: #cccccc;
}

.tables .title {
  float: left;
  margin-bottom: 5px;
  line-height: 38px;
  font-size: 16px;
}

.block {
  margin-top: 30px;
  text-align: center;
}
</style>
